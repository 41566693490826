import * as React from "react";
import BigHead from "../layouts/BigHead";

const NotFoundPage = () => {
  return (
    <BigHead
      title="404"
      description="Page Not Found"
      intro="Sorry, the page you are looking for is not here."
    >
      &nbsp;
    </BigHead>
  );
};

export default NotFoundPage;
